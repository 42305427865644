import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { Form, Button, Modal, Header, Icon, Message } from 'semantic-ui-react';

import { utcMoment, dateTimeFormatted } from '../../utilities/dateUtils';

class VinBlacklistForm extends Component {
  state = {
    id: null,
    expirationDate: null,
    vin: '',
    notes: '',
    errors: {}
  };

  componentDidMount() {
    const { vinBlacklist } = this.props;
    if (!isEmpty(vinBlacklist)) {
      const { id, expirationDate, vin, notes } = vinBlacklist;

      this.setState({
        id: id,
        expirationDate,
        vin,
        notes
      });
    }
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors } = this.state;

    const date = isEmpty(this.state.expirationDate)
      ? null
      : utcMoment(this.state.expirationDate);

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD HH:mm:ss') });
    };

    const validationRules = {
      vin: 'required'
    };

    const submitForm = () => {
      const { id, expirationDate, vin, notes } = this.state;

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          id: id,
          expirationDate: dateTimeFormatted(utcMoment(expirationDate)),
          vin,
          notes
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          {!this.state.id && 'Create New Vin Blacklist'}
          {this.state.id && 'Edit Vin Blacklist'}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Header size="small" color="blue" />
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.vin)}>
                  <label>Vin</label>
                  <input
                    name="vin"
                    value={this.state.vin}
                    onChange={onChangeHandler('vin').bind(this)}
                  />
                  {errors.vin && <span>{errors.vin}</span>}
                </Form.Field>
                <Form.Field>
                  <label>Expiration Date</label>
                  <DatePicker
                    style={{ padding: '0', width: '100%' }}
                    selected={date}
                    onChange={onDateChangeHandler('expirationDate').bind(this)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="YYYY-MM-DD HH:mm:ss ZZ"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Notes</label>
                  <input
                    name="notes"
                    value={this.state.notes}
                    onChange={onChangeHandler('notes').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting &&
            !this.state.id && (
              <Button color="green" onClick={submitForm} inverted>
                <Icon name="checkmark" /> Create
              </Button>
            )}

          {!submitting &&
            this.state.id && (
              <Button color="green" onClick={submitForm} inverted>
                <Icon name="checkmark" /> Update
              </Button>
            )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default VinBlacklistForm;
