import React, { Component } from 'react';
import Form from './Form';

class Edit extends Component {
  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting,
      vinBlacklist
    } = this.props;

    return (
      <Form
        isModalOpen={isModalOpen}
        onCloseHandler={onCloseHandler}
        onSubmitHandler={onSubmitHandler}
        error={error}
        submitting={submitting}
        vinBlacklist={vinBlacklist}
      />
    );
  }
}

export default Edit;
